import React, { useState } from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'

import { Layout, PageHeader, CardItem, Pagination } from '../components'
import { container, padding } from '../styles/global'
import { parseACF } from '../utils'

const NewsListing = props => {
    const { data, group, index, first, last, pageCount } = props.pageContext
    // console.log('data', data)

    if (typeof group !== 'undefined') {
        group.forEach((item, i) => (item.acf = JSON.parse(item.acf_json)))
    }

    const breadcrumbs = {
        heading: {
            title: 'Strive Financial',
            link: '/'
        },
        description: {
            title: 'News',
            link: undefined
        }
    }

    const renderNewsPosts = () => {
        return group.map((item, i) => {
            const first_row =
                (first && i === 0) || (!first && i === 0) || (!first && i === 1)
                    ? true
                    : false

            // console.log(item)
            return (
                <CardItem
                    key={i}
                    width={i === 0 ? 'full' : '1/3'}
                    first_row={first_row}
                    title={item.title}
                    slug={`/news/${item.slug}/`}
                    cats={item.cats}
                    description={item.acf.short_intro}
                    hero_image={
                        item.acf.hero_image ? item.acf.hero_image : undefined
                    }
                />
            )
        })
    }

    return (
        <Layout meta={data && data.seo}>
            {data && (
                <PageHeader
                    breadcrumbs={breadcrumbs}
                    hero_image={data.hero_image ? data.hero_image : undefined}
                    page_heading={
                        data.page_heading ? data.page_heading : undefined
                    }
                    short_intro={
                        data.short_intro ? data.short_intro : undefined
                    }
                />
            )}
            <Wrapper>
                <Container>
                    {typeof group !== 'undefined' && (
                        <>
                            <Grid>{renderNewsPosts()}</Grid>

                        {pageCount > 1 && (
                            <Pagination
                                slug={`news`}
                                index={index}
                                first={first}
                                last={last}
                                pageCount={pageCount}
                            />
                        )}
                        </>
                    )}
                </Container>
            </Wrapper>
        </Layout>
    )
}

const Wrapper = styled.div`
    ${tw`pb-12 bg-lightBlue`};
`

const Container = styled.div`
    ${container}
    ${padding}
`

const Grid = styled.div`
    ${tw`flex flex-wrap -mx-6`};
`
const Left = styled.div``
const Right = styled.div``

export default NewsListing
